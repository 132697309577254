import { IonApp, setupIonicReact } from '@ionic/react';
import React from 'react';
import AppRouter from './router/AppRouter';
import { ToastProvider } from '@agney/ir-toast';
import { SessionProvider } from './context/SessionContext';

setupIonicReact({});

function App() {
  return (
    <IonApp>
      <ToastProvider>
        <SessionProvider>
          <AppRouter />
        </SessionProvider>
      </ToastProvider>
    </IonApp>
  );
}

export default App;
