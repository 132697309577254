import React from 'react';
import 
{ IonContent
, IonHeader
, IonIcon
, IonItem
, IonLabel
, IonList
, IonMenu
, IonRouterLink
, IonTitle
, IonToolbar
} from '@ionic/react';
import { menuController } from '@ionic/core/components';
import { useHistory } from 'react-router-dom';

//Utils
import { logout } from '../../../utils/session';
import { ctxValue } from '../../../utils/config';
import logger from '../../../utils/logger';

//Icons
import { globeOutline, warning, logOut, chatbubbleOutline, notificationsOutline } from 'ionicons/icons';

//Styles
import styles from './Menu.module.css';

function Menu() {
  const history = useHistory();

  const handleOption = (option) => {
    menuController.close();
    switch (option) {
      case 'network':
        history.replace({ pathname:'/relying-party-list', state:{ refreshTimestamp:new Date() }});
        break;
      case 'notifications':
        history.push('/notifications');
        break;
      case 'delete':
        history.push('/reset-account');
        break;
      case 'feedback':
        history.push('/feedback');
        break;
      case 'signout':
        logout();
        history.replace(`/`);
        break;
      default:
        logger.error(`Menu: handleOption() called with invalid option: ${option}`);
      }
  };

  const renderNotificationsMenuItem = () => {
    if (ctxValue('ENV')==='PROD') {
      // this feature is not available in production yet
      return <></>
    }
    return <>
      <IonItem
        button='true'
        className={styles.IonItem}
        onClick={() => {handleOption('notifications');}}
        data-testid='menu-item-notifications'
      >
        <IonIcon icon={notificationsOutline} slot='start' className={styles.IonIcon} />
        <IonLabel className={styles.IonLabel}>Notifications</IonLabel>
      </IonItem>
    </>
  };

  return (
    <IonMenu side='start' contentId='main' type='overlay' swipeGesture='false'>

      <IonHeader className={styles.IonHeader} data-testid='menu-header'>
        <IonToolbar translucent className={styles.IonToolbar}>
          <IonTitle className={styles.ContainerText}>
            <h4>Menu</h4>
          </IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <IonList data-testid='menu-list'>

          <IonItem
            button='true'
            className={styles.IonItem}
            onClick={() => {handleOption('network');}}
            data-testid='menu-item-network'
          >
            <IonIcon icon={globeOutline} slot='start' className={styles.IonIcon} />
            <IonLabel className={styles.IonLabel}>IDgo<span className={styles.TradeMark}>&trade;&nbsp;</span>Network</IonLabel>
          </IonItem>
          
          {renderNotificationsMenuItem()}

          <IonItem
            button='true'
            className={styles.IonItem}
            onClick={() => {handleOption('delete');}}
            data-testid='menu-item-reset-account'
          >
            <IonIcon icon={warning} slot='start' className={styles.IonIcon} />
            <IonLabel className={styles.IonLabel}>Delete Account</IonLabel>
          </IonItem>

          <IonItem
            button='true'
            className={styles.IonItem}
            onClick={() => {handleOption('feedback');}}
            data-testid='menu-item-feedback'
          >
            <IonIcon icon={chatbubbleOutline} slot='start' className={styles.IonIcon} />
            <IonLabel className={styles.IonLabel}>Feedback</IonLabel>
          </IonItem>

          <IonItem
            button='true'
            className={styles.IonItem}
            onClick={() => {handleOption('signout');}}
            data-testid='menu-item-signout'
          >
            <IonIcon icon={logOut} slot='start' className={styles.IonIcon} />
            <IonLabel className={styles.IonLabel}>Sign Out</IonLabel>
          </IonItem>

        </IonList>
      </IonContent>

      <IonRouterLink
        className={styles.PrivacyPolicy}
        href='https://www.cozera.io/privacy-policy'
        target='_blank'
        data-testid='menu-privacy-policy'
      >
        Privacy policy
      </IonRouterLink>

    </IonMenu>
  );
}

export default Menu;
