import React, { createContext, useState, useMemo, useContext } from 'react';

const SubdomainContext = createContext();

export function SubdomainProvider(props) {
  const [subdomain, setSubdomain] = useState(undefined);

  const providerSubdomain = useMemo(() => ({ subdomain, setSubdomain }), [subdomain]);

  return (
    <SubdomainContext.Provider
      value={providerSubdomain}
      {...props}
    ></SubdomainContext.Provider>
  );
}

export function useSubdomain() {
  const subdomain = useContext(SubdomainContext);
  return subdomain;
}
