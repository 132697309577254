import 
{ logMessage
, clientActivity
, clientActivityNoToken
} from './services';

const logger = {
  debug: (msg, loginValue) => { log('debug', msg, loginValue); },
  info : (msg, loginValue) => { log('info' , msg, loginValue); },
  warn : (msg, loginValue) => { log('warn' , msg, loginValue); },
  error: (msg, loginValue) => { log('error', msg, loginValue); },
  event: (name, subdomain) => { _event(name, subdomain); },
  eventNT: (name, authKey) => { _eventNoToken(name, authKey); },
};

const log = async (level, msg, loginValue) => {
  switch (level) {
    case 'debug': console.debug(msg); break;
    case 'info' : console.info (msg); break;
    case 'warn' : console.warn (msg); break;
    case 'error': console.error(msg); break;
    default:
  }
  // Stop doing for now. Have been successful using DB logged into and Elastic lately.
  if (level==='debug') return;
  logMessage(level, msg.substring(0, 512), loginValue); // persisted in the mobile_web_log table (message column is only 512 chars)
};

const _event = async (eventName) => {
  clientActivity(eventName);
};

const _eventNoToken = async (eventName, authKey) => {
  clientActivityNoToken(eventName, authKey);
};

export default logger;