import React, { useState, useEffect } from 'react';
import { useSession } from "../../../context/SessionContext";

//Context
import SessionAlert from './SessionAlert/SessionAlert';

const SessionTimeout = () => {
  const { session } = useSession();
  const [isWarningModalOpen, setWarningModalOpen] = useState(false);

  useEffect(() => {
    if(session){
      setTimeout(() => {
        setWarningModalOpen(true);
      }, 119.5 * 60 * 1000);
    }
  }, [session]);

  return (
    <React.Fragment>
      {isWarningModalOpen && <SessionAlert isOpen={isWarningModalOpen} />}
    </React.Fragment>
  );
};
export default SessionTimeout;
