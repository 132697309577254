import { IonContent, IonPage, IonRouterLink, IonText } from "@ionic/react";
import React from 'react';
import Header from "../../layout/Header/Header";

//Styles
import styles from './Feedback.module.css';

function Feedback() {
  const feedbackEmail = 'feedback@idgo.io';

  return(
    <IonPage>
      <Header page='Feedback' />
      <IonContent>
        <div className={styles.Container}>
          <IonText className={styles.ContainerText} data-testid='feedback-paragraph-first-element'>
            We appreciate your feedback and recommendations to improve the <b>IDgo</b> service.
          </IonText>
          <IonText className={styles.ContainerText} data-testid='feedback-paragraph-second-element'>
            You can send your suggestions to:
            <IonRouterLink
              data-testid='email-feedback'
              className={styles.FeedbackMail}
              href={`mailto:${feedbackEmail}`}
            >
              {feedbackEmail}
            </IonRouterLink>
          </IonText>
          <IonText className={styles.ContainerText} data-testid='feedback-paragraph-third-element'>
            Thank you - The <b>IDgo</b> Team
          </IonText>
        </div>
      </IonContent>
    </IonPage>
  );
}

export default Feedback;