import React from 'react';
import {
  IonBackButton,
  IonButtons,
  IonHeader,
  IonMenuButton,
  IonTitle,
  IonToolbar,
} from '@ionic/react';

//Styles
import styles from './Header.module.css';

function Header({ page, backButtonDisabled, menuButton }) {
  const idGoTitle = (<span>IDgo<span className={styles.TradeMark}>&trade;&nbsp;</span>Network</span>);
  return (
    <IonHeader mode='ios' className={styles.IonHeader}>
      <IonToolbar className={styles.IonToolbar}>
        <IonButtons slot='start'>
          {menuButton ? (
            <IonMenuButton className={styles.MenuButton} mode='ios' autoHide={false} />
          ) : (
            <IonBackButton
              mode='ios'
              className={styles.IonBackButton}
              disabled={Boolean(backButtonDisabled)}
              id={backButtonDisabled ? styles.Disabled : ''}
            />
          )}
        </IonButtons>
        <IonTitle className={styles.IonTitle}>
          {page === 'IDgo Network' ? idGoTitle : page}
        </IonTitle>
      </IonToolbar>
    </IonHeader>
  );
}

export default Header;
