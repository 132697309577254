import React from 'react';
import 
{ IonItem
, IonToggle
, IonLabel
, IonButton
, IonImg
, IonList
, IonText
, IonAccordion
} from '@ionic/react';

import { ctxValue } from '../../../../utils/config';

// Directly import the stylesheet (could not figurate how to use "CSS Modules")
import './RelyingPartyItem.css';

function RelyingPartyItem ({ relyingParty, selectSubdomain, toggleVerifications }) {
  
  return (
    <IonAccordion className="RpAccordion" value={relyingParty.subdomain}>
      <IonItem key={relyingParty.id} slot="header" color="lightgray">
        <IonImg src={`https://mwlogos${ctxValue('LOGOENV').toLowerCase()}.z5.web.core.windows.net/${relyingParty.subdomain}.png?v3`} alt={`${relyingParty.subdomain}.png`} className="RpLogo" />
        <IonText className="RpName">{relyingParty.name}</IonText>
      </IonItem>

      <IonList slot="content">

        <IonItem>
          <IonLabel>{relyingParty.callCenterPhoneNumberName}</IonLabel>
          <IonText className="RpIVRNumber"><a href={`tel:${relyingParty.ivrPhoneNumber}`}>{relyingParty.ivrPhoneNumber}</a></IonText>
        </IonItem>

        { Boolean(relyingParty.proofsComplete) &&
          (
            <IonItem>
              <IonLabel>Enable Authentication</IonLabel>
              <IonToggle
                checked={Boolean(relyingParty.verificationsEnabled)}
                onClick={(e) => toggleVerifications(relyingParty.subdomain, relyingParty.name, (e?.target?.classList?.value.includes('toggle-checked'))?false:true)}
              />
            </IonItem>
          )
        }

        <IonItem>
          <IonButton className="dashboardBtn" color="light" shape="round" onClick={()=>selectSubdomain(relyingParty)}>Verifications</IonButton>
        </IonItem>

      </IonList>
    </IonAccordion>
  );
}

export default RelyingPartyItem;