import React, { useState } from 'react';
import { IonAlert } from '@ionic/react';

function SessionAlert({ isOpen }) {
  const [showAlert, setShowAlert] = useState(isOpen);

  const handleAllow = () => {
      setShowAlert(false);
  };

  return (
    <IonAlert
      isOpen={showAlert}
      onDidDismiss={() => setShowAlert(false)}
      id='SessionAlert'
      backdropDismiss='false'
      header={'Hey! Are you still there?'}
      message={'You have been inactive for almost 2 hours. You will be logged out soon.'}
      buttons={[
        {
          text: 'OK',
          handler: handleAllow,
        },
      ]}
    />
  );
}

export default SessionAlert;
