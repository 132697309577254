// Utilities
import logger from './logger';

/**
 * This function check to see if there is a Platform Authenticator availble for FIDO Registeration.
 * Additionally results are logged for post event diagnostics,
 * and notifications are sent to IDgo Agent to show mobile device events.
 */
export async function isAuthenticatorAvailble(loginValue) {
  let isAvailable=false;
  if (window.PublicKeyCredential) {
    isAvailable = await window.PublicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable()
      .then((available) => { return available })
      .catch((err) => {
        logger.error(`isAuthenticatorAvailble(): Error thrown checking for Platform Authenticator() - ${err}`, loginValue);
        logger.event('fido-registeration-failure'); // post message that Registration failed
        return false;
      });
  }

  if (isAvailable) {
    logger.event('fido-available'); // post message that Platform Authenticator is available
    return true;
  }

  logger.warn('isAuthenticatorAvailble(): Platform authenticator is NOT available', loginValue);
  logger.event('fido-not-available'); // post message that Platform Authenticator is NOT available
  return false;
};

export function isEmail(value) {
  const reg = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  if (reg.test(value)) {
    return true;
  }
  return false;
}

export function isMobile(value) {
  const reg = /^[0-9]{10}$/;
  if (reg.test(value)) {
    return true;
  }
  return false;
}

export function isLoginMoniker(value) {
  const reg = /^S[0-9]*\.\S*\.\S*$/gi;
  if (reg.test(value)) {
    return true;
  }
  return false;
}

export function loginTypeFromValue(value) {
  if (value==='cookie')   return 'loginMoniker';
  if (isMobile(value))    return 'mobile';
  return;
}

export function dataURLToBlob(imageURL) {
  let binary = window.atob(imageURL.split(',')[1]);
  let array = [];
  for (let i = 0; i < binary.length; i++) {
    array.push(binary.charCodeAt(i));
  }
  return new Blob([new Uint8Array(array)], { type: 'image/jpg' });
}

export function formatPhoneNumber(mobileNumber) {
  if (!mobileNumber) return '';
  if (!mobileNumber.match(/^[0-9]{10}$/)) return mobileNumber;

  let o = '';
  mobileNumber.replace(/^\D*(\d{0,3})\D*(\d{0,3})\D*(\d{0,4})/, function (_match, g1, g2, g3) {
    if (g1.length) {
      o += g1;
      if (g1.length===3) {
        o += '-';
        if (g2.length) {
          o += '' + g2;
          if (g2.length===3) {
            o += '-';
            if (g3.length) {
              o += g3;
            }
          }
        }
      }
    }
  });
  return o;
};

export function iOsVersionSupportsWebPush(osVersion) {
  if (!osVersion) return {supported:false, reason:'version string unavailable'};
  const parts = osVersion.split('.');
  if (parts.length < 2             ) return {supported:false, reason:`iOS version ${osVersion} detected, unknown format`};
  if (parts[0] < 16                ) return {supported:false, reason:`iOS version ${osVersion} detected, must be 16.4 or greater`};
  if (parts[0]===16 && parts[1] < 4) return {supported:false, reason:`iOS version ${osVersion} detected, must be 16.4 or greater`};
  return {supported:true, reason:`iOS version ${osVersion} detected`};
};