import React from 'react';
import { Route, useLocation } from 'react-router-dom';
import { haveFidoSession } from '../../utils/session';
import logger from "../../utils/logger";

/**
 * Restricted route that unauthenticated user can see.
 * We don’t want to show the sign-in page after logged in to the site.
 * If an authorised user goes to the sign-in page, we will redirect to either
 * the dashboard page (if we have subdomain context), or the relying party list page.
 */
const PublicRoute = ({ component: Component, ...rest }) => {
  let location = useLocation();

  // Ionic page loading uses a ion-page-invisible style to hide pages. Somehow there is an issue where
  // this class is not removed randomly. This approach was on I found that is hacky, but appears to work.
  React.useEffect(() => {
    setTimeout(() => {
      const main = document.getElementById("main-content");
      if (!main) return;
      const page = main.querySelector(".ion-page.ion-page-invisible");
      if (!page) return;
      logger.debug('PrivateRoute: removing class ion-page-invisible')
      page.classList.remove("ion-page-invisible");
    }, 100);
  }, [location]);

  const overrideRoute = () => { // (rest) => {
    const fidoSession = haveFidoSession();
    //logger.debug(`PublicRoute: {component: ${rest.name}, haveFidoSession:${fidoSession}}`);
    return fidoSession;
  };

  return (
    <Route
      {...rest}
      render={(props) =>
        overrideRoute(rest) ? (
          <Component {...props} />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};

export default PublicRoute;
