import React, { createContext, useState, useMemo, useContext } from 'react';

const FIDOContext = createContext();

export function FIDOProvider(props) {
  const [FIDO, setFIDO] = useState(false);

  const providerFIDO = useMemo(() => ({ FIDO, setFIDO }), [FIDO]);

  return (
    <FIDOContext.Provider
      value={providerFIDO}
      {...props}
    ></FIDOContext.Provider>
  );
}

export function useFIDO() {
  const FIDO = useContext(FIDOContext);

  return FIDO;
}
