import React, { useEffect, useState } from 'react';
import 
{ useIonAlert
, IonContent
, IonPage
, IonText
, IonButton
} from "@ionic/react";
import Header from '../../layout/Header/Header';

// Contexts
import { useLogin } from '../../../context/LoginContext';

// Utilities
import logger from '../../../utils/logger';
import { ctxValue } from '../../../utils/config';
import { useToast } from '@agney/ir-toast';
import UAParser from 'ua-parser-js';

// Service APIs
import 
{ registerWebPushSubscription
, unregisterWebPushSubscription
} from '../../../utils/services';
import { iOsVersionSupportsWebPush } from '../../../utils/validateData';

// Styles
import styles from './Notifications.module.css';

//Images
import iso_share from './iso-share.png';
import a2hs from './a2hs.png';
import a2hs_add from './a2hs-add.png';
import settings_notifications  from './settings-notifications.png';
import notifications_idgo  from './notifications-idgo.png';
import allow_notifications  from './allow-notifications.png';
import banner_style_persistent  from './banner-style-persistent.png';

const vapidPublicKey = ctxValue('VAPID_PUBLIC_KEY');
const userAgent = new UAParser();

function Notifications() {
  const { loginValue } = useLogin();
  const [ showIonAlert, hideIonAlert ] = useIonAlert();
  const Toast = useToast();
  const [ supportsPushMessages, setSupportsPushMessages ] = useState(false);
  const [ haveSubscription, sethaveSubscription ] = useState(false);
  const [ noPushMessageReasons, setNoPushMessageReasons ] = useState([]);
  const [ showIosA2HResolution, setShowIosA2HResolution ] = useState(false);
  const [ showIosProTip, setShowIosProTip ] = useState(false);

  useEffect(() => {
    let notSupportedReasons = [];
    if (!('serviceWorker' in navigator)) notSupportedReasons.push(`ServiceWorker support not available.`);
    const uaOS = userAgent.getOS();
    if (uaOS?.name==='iOS') {
      // We have a few checks for iOS browsers
      const uaBrowser = userAgent.getBrowser();
      if (uaBrowser?.name!=='Safari' && uaBrowser?.name!=='Mobile Safari') {
        // Safari is require
        notSupportedReasons.push(`"${uaBrowser?.name}" detected, iOS devices must register from a Safari browser.`);
      }
      const info = iOsVersionSupportsWebPush(uaOS?.version);
      if (info.supported===false) {
        // Must be version 16.4 or greater
        notSupportedReasons.push(info.reason);
      } else if (!('Notification' in window)) {
        // OS is 16.4 or greater and no Notifications is most likely because the user is in a Browser not a Home Screen web app
        notSupportedReasons.push(`Notification are only supported from Home Screen web apps.`);
        setShowIosA2HResolution(true);
      }
      // if no blockers and we are an iOS device, show pro-tips
      if (notSupportedReasons.length===0) {
        setShowIosProTip(true);
      }
    } else {
      // for non-iOS devices
      if (!('Notification' in window)) notSupportedReasons.push(`Notification support not available.`);
    }

    if (notSupportedReasons.length > 0) {
      setNoPushMessageReasons(notSupportedReasons);
      return;
    }

    async function __registerServiceWorker() {
      try {
        await navigator.serviceWorker.register('service-worker.js');
        const sw = await navigator.serviceWorker.ready;
        setSupportsPushMessages(true);
        sw.pushManager.getSubscription()
          .then((subscription) => {
            if (subscription) {
              sethaveSubscription(true);
            } else {
              sethaveSubscription(false);
            }
          });       
      } catch (err) {
        logger.warn(`Notifications: navigator.serviceWorker.register - Error: ${err}`, loginValue);
        setSupportsPushMessages(false);
        Toast.create({ message: `Notification ${err}`, color: 'warning', position: 'top', duration: 3000}).present();
      }
    };

    __registerServiceWorker();

  }, [loginValue, Toast]);

  const subscribeUser = async () => {
    function __urlB64ToUint8Array(key) {
      const padding = '='.repeat((4 - key.length % 4) % 4);
      const base64 = (key + padding)
        .replace(/-/g, '+')
        .replace(/_/g, '/');
      const rawData = window.atob(base64);
      const outputArray = new Uint8Array(rawData.length);
      for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
      }
      return outputArray;
    };

    async function __registerWebPushSubscription(subAsObject, authKey) {
      const response = await registerWebPushSubscription(subAsObject, authKey);
      if (response?.status===200) {
        sethaveSubscription(true);
      } else {
        Toast.create({ message: `${response?.message}`, color: 'warning', position: 'top', duration: 3000}).present();
      }
    };

    try {
      const publicKeyAsArray = __urlB64ToUint8Array(vapidPublicKey)
      const sw = await navigator.serviceWorker.ready;
      sw.pushManager.subscribe({userVisibleOnly: true, applicationServerKey: publicKeyAsArray})
        .then((subscription) => {
          // The returned subscription needs to be stringified/parsed into a js object (not sure why, but it works)
          const subAsObject = JSON.parse(JSON.stringify(subscription));
          __registerWebPushSubscription(subAsObject, subAsObject.keys.auth);
        })
        .catch((err) => {
          logger.warn(`Notifications: pushManager.subscribe() failed. - Error: ${err?.message}`, loginValue);
          if (err?.name==='NotAllowedError') {
            showIonAlert({
              header: 'Notifications disabled!',
              subHeader: `You have Notifications disabled for this site.`,
              message: `Enable notifications for this site and try again.`,
              buttons: [{text:'OK', handler: () => hideIonAlert()}]
            });
          } else {
            Toast.create({ message: err?.message, color: 'warning', position: 'top', duration: 5000}).present();
          }
        });
    } catch (err) {
      logger.warn(`Notifications: subscribeUser() catch: pushManager.subscribe() failed. - Error: ${err?.message}`, loginValue);
      Toast.create({ message: err?.message, color: 'warning', position: 'top', duration: 3000}).present();
    }
  };

  const unSubscribeUser = async () => {
    async function __unregisterWebPushSubscription(authKey) {
      const response = await unregisterWebPushSubscription(authKey);
      if (response?.status!==200) {
        Toast.create({ message: response?.message, color: 'warning', position: 'top', duration: 3000}).present();
      }
    };

    const sw = await navigator.serviceWorker.ready;
    sw.pushManager.getSubscription()
      .then((subscription) => {
        if (!subscription) {
          logger.error(`Notifications: Failed to unsubscribe from Push Service. Value "subscription" was undefined`, loginValue);
          return;
        }
        subscription.unsubscribe();
        // The returned subscription needs to be stringified/parsed into a js object (not sure why, but it works)
        const subAsObject = JSON.parse(JSON.stringify(subscription));
        __unregisterWebPushSubscription(subAsObject.keys.auth);
      })
      .then(sethaveSubscription(false))
      .catch(err => logger.error(`Notifications: Failed to unsubscribe from Push Service. - Error: ${err}`, loginValue));
  };

  const toggleNotifications = async () => {
    if (haveSubscription) {
      await unSubscribeUser();
    } else {
      await subscribeUser();
    }
  };

  const testNotificationFromServiceWorker = () => {
    const testNotification = (reg) => {
      const options = 
        { body: `Test notification from ServieWorker`
        , icon: './favicon.ico'
        }
      reg.showNotification('Test Notification', options)
        .catch(err => {
          Toast.create({ message: `${err?.message}`, color: 'warning', position: 'top', duration: 4000}).present();
        });
    }
    navigator.serviceWorker.getRegistration()
      .then((registeration) => testNotification(registeration));
  };

  const renderIosA2HSSteps = () => {
    return <>
      <IonText className={styles.Resolution}>Resolution:</IonText>
      <ol>
        <li>
          <div>Click the "Share" menu item</div>
          <div><img className={styles.ResolutionImage} src={iso_share} alt=''></img></div>
        </li>
        <li>
          <div>Select "Add to Home Screen"<br/>* You may have to scroll up to see it!</div>
          <div><img className={styles.ResolutionImage} src={a2hs} alt=''></img></div>
        </li>
        <li>
          <div>Click the "Add" button top right</div>
          <div><img className={styles.ResolutionImage} src={a2hs_add} alt=''></img></div>
        </li>
        <li>
          <div>Open the new IDgo application from your Home Screen and Enable Notifications</div>
          <div></div>
        </li>
      </ol>
    </>
  };

  const renderIosProTip = () => {
    return <>
      <IonText className={styles.ProTip}>iOS Pro Tip:</IonText>
      <ol>
        <li>
          <div>Select "Notifications" from Settings</div>
          <div><img className={styles.ProTipImage} src={settings_notifications} alt=''></img></div>
        </li>
        <li>
          <div>Scorll down to "IDgo" and select it</div>
          <div><img className={styles.ProTipImage} src={notifications_idgo} alt=''></img></div>
        </li>
        <li>
          <div>Ensure "Allow Notifications" is enabled</div>
          <div><img className={styles.ProTipImage} src={allow_notifications} alt=''></img></div>
        </li>
        <li>
          <div>Set "Banner Style" to Persistent</div>
          <div><img className={styles.ProTipImage} src={banner_style_persistent} alt=''></img></div>
        </li>
      </ol>
    </>
  };

  return(
    <IonPage>
      <Header page='Notifications' menuButton={true} />
      <IonContent>
        <div className={styles.Container}>
          {supportsPushMessages ?
            (
              <>
                <IonButton
                  expand='block'
                  onClick={toggleNotifications}
                  className={styles.IonButton}
                  type='submit'
                >
                  {haveSubscription ? 'Disable' : 'Enable'} Push Messages
                </IonButton>

                <IonButton
                  expand='block'
                  onClick={testNotificationFromServiceWorker}
                  className={styles.TestIonButton}
                  type='submit'
                >
                  Test Notification via Service Worker
                </IonButton>
                {showIosProTip && renderIosProTip()}
              </>
            ) : (
              <>
                <IonText className={styles.ContainerText}>
                  This browser does not support Push Notifications
                </IonText>

                <div className={styles.ReasonsContainer}>
                  <IonText className={styles.Reasons}>Reason(s)</IonText>
                  <ul>
                    {noPushMessageReasons && noPushMessageReasons
                      .map((msg) => (
                        <li className={styles.ReasonBullet}>
                          {msg}
                        </li>
                      ))
                    }
                  </ul>
                </div>

                {showIosA2HResolution && renderIosA2HSSteps()}
              </>
            )
          }
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Notifications;