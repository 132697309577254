import React, { useEffect, useState } from 'react';
import
{ IonPage
, IonContent
, IonImg
, IonItemDivider
, IonText
, IonAlert
} from '@ionic/react';

//Context
import { useVerifyCounter } from '../../../context/VerifyCounterContext';
import { useSubdomain } from '../../../context/SubdomainContext';

//Styles
import styles from './Dashboard.module.css';

//Utils
import 
{ getVerificationRequest
, getRelyingPartyInfo
, logSigninEvent
, verifyResponse
} from '../../../utils/services';
import { useToast } from '@agney/ir-toast';
import logger from '../../../utils/logger';

//Images
import logo from '../../../assets/logos/idgo_lockup.png';
import Header from "../../layout/Header/Header";

function Dashboard(props) {
  const Toast = useToast();
  const [ showAuthConfirmAlert, setShowAuthConfirmAlert ] = useState(false);
  const [ authConfMsg, setAuthConfMsg ] = useState({messageHeader:'',messageBody:'',verificationRequestId:null});
  const [ subdomainName, setSubdomainName ] = useState();
  const { subdomain } = useSubdomain();
  const { verifyCounter } = useVerifyCounter();
  const [ signinRequest, setSigninRequest ] = useState();

  useEffect(() => {
    if (!props?.history?.location?.state?.signinRequest) return;
    setSigninRequest(props?.history?.location?.state?.signinRequest);
  }, [props?.history?.location?.state]);

  useEffect(() => {
    if (!subdomain) return;
    async function __getRPName() {
      const response = await getRelyingPartyInfo(subdomain);
      if (response.status !== 200) return;
      setSubdomainName(response.data.name);
    }
    __getRPName();
  }, [subdomain]);

  useEffect(() => {
    if (!signinRequest) return;

    async function __loadAuthConfirmationAlert () {
      if (!verifyCounter) return;
      
      const response = await getVerificationRequest(subdomain, verifyCounter);

      if (response?.status !== 200) {
        await logSigninEvent(signinRequest, 50.02);
        Toast.create({ message: `Error retrieving auth request. HTTP status:${response?.status}, message:${response?.message}`, color: 'warning', duration: 3000, position: 'top' }).present();
        return;
      }

      const vr = response?.data?.verificationRequest;
      if (vr?.status) {
        // Showing users the fact that a authorization request has already been completed has created some confusion, so we are going to hide it for now...
        //Toast.create({ message: `This authentication request has been completed with "${vr.status}"`, color: 'success', duration: 3000, position: 'top' }).present();
        return;
      }
      
      await logSigninEvent(signinRequest, 50.03);

      setAuthConfMsg({
        messageHeader: vr?.messageHeader,
        messageBody: vr?.messageBody,
        verificationRequestId: vr?.verificationRequestId
      })
      setShowAuthConfirmAlert(true);
    }

    if (signinRequest.signinType==='e') {
      logger.event('enrollment-complete'); // post message to IDgo Agent
      return;
    }
    if (signinRequest.signinType==='a') {
      __loadAuthConfirmationAlert();
      return;
    }
  }, [Toast, subdomain, verifyCounter, signinRequest]);
  
  const renderDashboard = () => {
    if (signinRequest?.signinType==='e') {
      return <>
        <h1 className={styles.HeaderMessage}>You're all set!</h1>
        <IonItemDivider className={styles.IonItemDivider} id={styles.TextDivider} />
        <p className={styles.HeaderSubMessage}>
          Now you can use <b>IDgo</b> to securely authenticate online, in the contact center or in person.
        </p>
      </>
    }
    if (signinRequest?.signinType==='a') {
      return <>
        <h1 className={styles.HeaderMessage}>You've been verified!</h1>
        <IonItemDivider className={styles.IonItemDivider} id={styles.TextDivider} />
        <p className={styles.HeaderSubMessage}>
          Thank you for using <b>IDgo</b> to securely authenticate yourself.
        </p>
      </>
    }
  };

  return (
    <IonPage>
      <Header page={subdomainName} menuButton={true} /> 
      <IonContent className='ion-padding' id={styles.ContentDashboard}>
        <div className={styles.DashboardPage}>

          <IonAlert
            isOpen={showAuthConfirmAlert}
            onDidDismiss={() => setShowAuthConfirmAlert(false)}
            header={authConfMsg.messageHeader}
            message={authConfMsg.messageBody}
            buttons={
              [ { text: 'No', role: 'cancel', handler: () => { verifyResponse(subdomain, authConfMsg.verificationRequestId, 'rejected') }}
              , { text: 'Yes',                handler: () => { verifyResponse(subdomain, authConfMsg.verificationRequestId, 'pass') }}
              ]}
          />

          <IonText>
            { renderDashboard() }
          </IonText>
  
          <div className={styles.CloseThisWindow}>
            (This window or tab can now be closed)
          </div>

          <div className={styles.ContainerImg}>
            <IonImg src={logo} data-testid='id-go-logo' alt='id-go-logo' />
          </div>

        </div>
      </IonContent>
    </IonPage>
  );
}

export default Dashboard;