import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import 
{ IonPage
, IonContent
, IonButton
, IonText
, IonImg
} from '@ionic/react';
import { useToast } from '@agney/ir-toast';

//Utils
import { setAccessToken } from '../../../utils/session';
import { mobileNumberVerifyInfo, mobileNumberVerifyUserResponse } from '../../../utils/services';

//Images
import cozera from '../../../assets/logos/idgo_lockup.png';

//Styles
import styles from './MobileNumberVerify.module.css';

function MobileNumberVerify() {
  const Toast = useToast();
  const { mnvKey, customCode } = useParams();
  const [ subdomain, setSubdomain ] = useState();
  const [ smsMagicLinkRequestId, setSmsMagicLinkRequestId ] = useState();
  const [ loginDeviceIpInfo, setLoginDeviceIpInfo ] = useState({});
  const [ threatsDetected, setThreatsDetected ] = useState(false);
  const [ buttonsDisabled, setButtonsDisabled ] = useState(true);
  const [ choiseMade, setChoiseMade ] = useState(false);
  const [ initializing, setInitializing ] = useState(true);
  const [ rpName, setRpName ] = useState();

  useEffect(() => {
    async function __mobileNumberVerifyInfo(mnvKey, customCode) {
      const response = await mobileNumberVerifyInfo(mnvKey, customCode);
      setInitializing(false);
      if (response?.status===404) {
        setChoiseMade(true);
        
        Toast.create({ message: response?.message, color: 'warning', position: 'top', duration: 10000}).present();
        return;
      }
      if (response?.status!==200) {
        setChoiseMade(true);
        Toast.create({ message: response?.message, color: 'danger', position: 'top', duration: 10000}).present();
        return;
      }
      setButtonsDisabled(false);
      setSmsMagicLinkRequestId(response.data?.smsMagicLinkRequestId);
      setSubdomain(response.data?.subdomain);
      setAccessToken(response.data.token, false);
      if (response.data?.loginDeviceIpInfo) {
        setLoginDeviceIpInfo(response.data?.loginDeviceIpInfo)
      }
      setThreatsDetected(response.data?.threatsDetected)
    }

    if (mnvKey) {
      if (mnvKey==='displayonly') {
        setInitializing(false);
        setRpName('Enterprise 1');
        setLoginDeviceIpInfo( {location : {city:'Portland', region: {name:'Oregon'}}} );
        return;
      }
      __mobileNumberVerifyInfo(mnvKey, customCode);
      return;
    }

  }, [mnvKey, customCode, Toast]);

  const handleUserResponse = async (userResponse) => {
    await mobileNumberVerifyUserResponse(subdomain, smsMagicLinkRequestId, userResponse);
    setChoiseMade(true);
  };

  const renderThreatsDetected = () => {
    return <>
      <IonText className={styles.ContainerText}>
        <h2>
          IDgo Threat Detection
        </h2>
        <div>
          has blocked this verification attempt.
        </div>
        <p>
          If you are attempting to Login to your Online Banking account now, we are sorry for the inconvenience.
        </p>
        <p>
          If you are not attempting to Login to your Online Banking account, IDgo Threat Detection has identified this Login as suspicious.
          We highly recommend you reset you Online Banking account Password immediately.
        </p>
      </IonText>
    </>
  };

  const renderChoise = () => {
    return <>
      <IonText className={styles.ContainerText}>
        <h2 className={styles.Header}>
          Please Review
        </h2>
        <h2>
          If someone is ASKING you to choose Yes,
          do NOT do so, this is a scam.
          No employee of {rpName} will EVER ask you to do so.
        </h2>
        { !loginDeviceIpInfo?.location?.city || !loginDeviceIpInfo?.location?.region?.name
          ? (
              <h2 className={styles.questionParagraph}>
                Are you trying to sign in?
              </h2>
            )
          : (
              <h2 className={styles.questionParagraph}>
                Are you trying to sign in from {loginDeviceIpInfo.location.city} {loginDeviceIpInfo.location.region.name}?
              </h2>
            )
        }
      </IonText>
      <div className={styles.ButtonBox}>
        <IonButton
          className={styles.ButtonNo}
          onClick={() => handleUserResponse('rejected')}
          disabled={buttonsDisabled}
          shape='round'
          color='danger'
          size='large'
          >No
        </IonButton>
        <div className={styles.ButtonSpacer}></div>
        <IonButton
          className={styles.ButtonYes}
          onClick={() => handleUserResponse('pass')}
          disabled={buttonsDisabled}
          shape='round'
          color='success'
          size='large'
          >Yes
        </IonButton>
      </div>
    </>
  };

  const renderChoiseMade = () => {
    return <>
      <IonText className={styles.ContainerText}>
        <h1>Thank you for confirming your Mobile Number.</h1>
        <h5>(This window or tab can now be closed)</h5>
      </IonText>
    </>
  };

  const render = () => {
    if (initializing   ) return <></>;
    if (choiseMade     ) return renderChoiseMade();
    if (threatsDetected) return renderThreatsDetected();
    return renderChoise();
  };

  return (
    <IonPage>
      <IonContent className='ion-padding'>
        <div className={styles.BasePage}>
          { render() }
          <div className={styles.Footer}>
            <IonImg src={cozera} data-testid='id-go-logo' alt='id-go-logo' className={styles.IonImg} />
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
}

export default MobileNumberVerify;