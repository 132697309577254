import React, { createContext, useState, useMemo, useContext } from 'react';

const LoginContext = createContext();

export function LoginProvider(props) {
  const [loginValue, setLoginValue] = useState(``);
  const providerEmail = useMemo(() => ({ loginValue, setLoginValue }), [loginValue]);

  return (
    <LoginContext.Provider
      value={providerEmail}
      {...props}
    ></LoginContext.Provider>
  );
}

export function useLogin() {
  return useContext(LoginContext);
}
