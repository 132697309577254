import React, { createContext, useState, useMemo, useContext } from 'react';

const SessionContext = createContext();

export function SessionProvider(props) {
  const [session, setSession] = useState(false);
  const providerSession = useMemo(() => ({ session, setSession }), [session]);

  return (
    <SessionContext.Provider
      value={providerSession}
      {...props}
    ></SessionContext.Provider>
  );
}

export function useSession() {
  return useContext(SessionContext);
}
