// config.js

const getEnvFromHref = (href) => {
  /**
   * Typically when developing in a localhost environment, the identity service typically runs on port 3000, 
   * so 3001 can be used to run mobile web. If testing against the K8S DEV instance of the identity service,
   * then 3000 is open to run the mobile web.
   */
  // when running identity svc and mobile web locally needed for FIDO to work
  if (href.startsWith('http://localhost:3001'))     return 'LOCALDEV';  
  if (href.includes  ('.ngrok.io'))                 return 'DEV';  
  if (href.startsWith('http://localhost'))          return 'DEV';
  if (href.startsWith('https://dev.id-go.com'))     return 'DEV';
  if (href.startsWith('https://uat.id-go.com'))     return 'UAT';
  if (href.startsWith('https://id-go.com'))         return 'PROD';
  // legacy domains for lingering SMS messages with links to these domain
  if (href.startsWith('https://idgo.dev.cozera.io')) return 'DEV';
  if (href.startsWith('https://idgo.uat.cozera.io')) return 'UAT';

  console.error('config.js: ERROR - Unable to derive Environment!');
  return undefined;
};

const g_env = getEnvFromHref(window.location.href);
console.info(`config.js: Environment: ${g_env}`);

const g_envConfig =
{ 'LOCALDEV': { IDENTITY_SERVICE    : 'http://localhost:3000'
              , PUBNUB_SUBSCRIBE_KEY: 'sub-c-30fdb813-0370-4b27-80b6-6ea4ebcd3096'
              , VAPID_PUBLIC_KEY    : 'BLcqE0CKQT3bf_7PYhI1tkOYLa3c88dbxrax96Nyn2savzjgN6GzUdq_lUkAjB6Z-lL7ASluAxoejYv9VHQwVk4'
              }
, 'DEV'     : { IDENTITY_SERVICE    : 'https://devapi.id-go.com/service-identity'
              , PUBNUB_SUBSCRIBE_KEY: 'sub-c-30fdb813-0370-4b27-80b6-6ea4ebcd3096'
              , VAPID_PUBLIC_KEY    : 'BLcqE0CKQT3bf_7PYhI1tkOYLa3c88dbxrax96Nyn2savzjgN6GzUdq_lUkAjB6Z-lL7ASluAxoejYv9VHQwVk4'
              }
, 'UAT'     : { IDENTITY_SERVICE    : 'https://uatapi.id-go.com/service-identity'
              , PUBNUB_SUBSCRIBE_KEY: 'sub-c-a77314d6-5076-11eb-9d3f-7e8713e36938'
              , VAPID_PUBLIC_KEY    : 'BBb5SE9-MpXOC_xWrOwMmL0l73wqUaIbOvVUC9vbuUQzERF6j6G3Gnb4wQeo2Rsj4WJerUocc8stWzTs1JCZCGo'
              }
, 'PROD'    : { IDENTITY_SERVICE    : 'https://prodapi.id-go.com/service-identity'
              , PUBNUB_SUBSCRIBE_KEY: 'sub-c-76dbf714-9ab1-11ea-8d30-d29256d12d3d'
              , VAPID_PUBLIC_KEY    : 'BAIUlwsCnNJjEOWNluDZRpALit9qoqlrjCgzsv4H0k8sSgTcd-UA6FP_7fCC2nTIDJAjWuT-Rd-T-c2lxBZ3gFg'
              }
};

export const ctxValue = (key) => {
  switch (key.toUpperCase()) {

    case 'ENV':
      return g_env;

    case 'LOGOENV':
      return (g_env==='LOCALDEV') ? 'DEV' : g_env;

    default:
      if (!(g_env in g_envConfig)) {
        console.error('Error: env <' + g_env + '> not defined in relying_party_env_cfg')
        return;
      }
      if (!(key in g_envConfig[g_env])) {
        console.error('Error: key <' + key + '> not defined in relying_party_env_cfg['+g_env+']')
        return;
      }
      return g_envConfig[g_env][key];
  }
};